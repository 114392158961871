<template>
  <nav class="relative bg-white shadow dark:bg-gray-800 page-wrap" v-bind="!isOpen">
    <div class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
      <div class="flex items-center justify-between">
        <div>
          <NuxtLink
            class="text-2xl font-bold text-gray-800 transition-colors duration-300 transform dark:text-gray-300 lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300 flex items-center gap-2"
            to="/"
          >
            <img src="@/assets/images/logo.png" alt="Logo" class="w-12 h-12" />
            {{ $t('films_gratis') }}
          </NuxtLink>
        </div>

        <!-- Mobile menu button -->
        <div class="flex md:hidden">
          <button
            v-cloak
            :aria-label="$t('toggle_menu')"
            class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
            type="button"
            @click="isOpen = !isOpen"
          >
            <svg
              v-show="!isOpen"
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 8h16M4 16h16" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <svg
              v-show="isOpen"
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div
        v-cloak
        :class="[isOpen ? 'translate-x-0 opacity-100 ' : 'opacity-0 -translate-x-full']"
        class="bg-white dark:bg-gray-800 absolute inset-x-0 z-20 w-full px-6 py-4  md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center"
      >
        <div class="flex flex-col md:flex-row md:mx-6">
          <NuxtLink
            class="my-2 align-middle text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"
            to="/"
          >
            {{ $t('accueil') }}
          </NuxtLink>
          <NuxtLink
            class="my-2 transition-colors duration-300 transform text-gray-700 dark:text-gray-200 nav-link md:mx-4 md:my-0"
            to="/contact"
          >
            {{ $t('contact') }}
          </NuxtLink>
          <NuxtLink
            class="my-2 transition-colors duration-300 transform text-gray-700 dark:text-gray-200 nav-link md:mx-4 md:my-0"
            to="/about"
          >
            {{ $t('a_propos') }}
          </NuxtLink>
          <!-- Dark mode switcher -->
          <button
            id="theme-toggle"
            class="text-gray-500 dark:text-gray-400  rounded-lg text-sm"
            type="button"
          >
            <svg
              id="theme-toggle-dark-icon"
              class="w-5 h-5 hidden"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
              />
            </svg>
            <svg
              id="theme-toggle-light-icon"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                fill-rule="evenodd"
              />
            </svg>
          </button>
          <!-- Dark mode switcher end -->
        </div>

        <div class="flex justify-center md:block" style="display: none">
          <a
            class="relative text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300"
            href="#"
          >
            <svg
              class="dark:fill-white fill-black"
              clip-rule="evenodd"
              fill-rule="evenodd"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.965 2.381c3.593 1.946 6.035 5.749 6.035 10.119 0 6.347-5.153 11.5-11.5 11.5s-11.5-5.153-11.5-11.5c0-4.37 2.442-8.173 6.035-10.119l.608.809c-3.353 1.755-5.643 5.267-5.643 9.31 0 5.795 4.705 10.5 10.5 10.5s10.5-4.705 10.5-10.5c0-4.043-2.29-7.555-5.643-9.31l.608-.809zm-4.965-2.381v14.826l3.747-4.604.753.666-5 6.112-5-6.101.737-.679 3.763 4.608v-14.828h1z"
              />
            </svg>
            <span class="absolute top-0 left-0 p-1 text-xs text-white bg-blue-500 rounded-full" />
          </a>
        </div>
      </div>
    </div>
    <slot />
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    let themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon')
    let themeToggleLightIcon = document.getElementById('theme-toggle-light-icon')

    // Change the icons inside the button based on previous settings
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      // themeToggleLightIcon.classList.remove('hidden');
      themeToggleDarkIcon.classList.toggle('hidden')
      themeToggleLightIcon.classList.toggle('hidden')
      document.documentElement.classList.add('dark')
    } else {
      // themeToggleDarkIcon.classList.remove('hidden');
    }

    const themeToggleBtn = document.getElementById('theme-toggle')

    themeToggleBtn.addEventListener('click', function() {
      themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon')
      themeToggleLightIcon = document.getElementById('theme-toggle-light-icon')

      // toggle icons inside button
      themeToggleDarkIcon.classList.toggle('hidden')
      themeToggleLightIcon.classList.toggle('hidden')

      // if set via local storage previously
      if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
          document.documentElement.classList.add('dark')
          localStorage.setItem('color-theme', 'dark')
        } else {
          document.documentElement.classList.remove('dark')
          localStorage.setItem('color-theme', 'light')
        }

        // if NOT set via local storage previously
      } else if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark')
        localStorage.setItem('color-theme', 'light')
      } else {
        document.documentElement.classList.add('dark')
        localStorage.setItem('color-theme', 'dark')
      }
    })
  }
}
</script>

<style scoped>
.nav-link:hover {
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
</style>
